import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hang Squat Clean 2-2-2-2-2\\@90% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-Power Cleans (135/95)`}</p>
    <p>{`42-Wall Balls (20/14)`}</p>
    <p>{`84-Double Unders`}</p>
    <p>{`15-Power Cleans`}</p>
    <p>{`30-Wall Balls`}</p>
    <p>{`60-Double Unders`}</p>
    <p>{`9-Power Cleans`}</p>
    <p>{`18-Wall Balls`}</p>
    <p>{`36-Double Unders`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p">{`*`}{`This Saturday we’ll have free Derby Day classes at 9:00 & 10:00am!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      